import * as tslib_1 from "tslib";
import { Subject } from "rxjs/Subject";
import { initialize } from "launchdarkly-js-client-sdk";
import { environment } from "../../../environments/environment";
import { SessionManagementService } from "../session-management.service";
import { StoreService } from "../store.service";
var LaunchDarklyService = /** @class */ (function () {
    // Defaults, do not overwrite. Initialized in constructor ONLY.
    // private readonly defaultFlagData: Object;
    // private readonly defaultShowADR: boolean;
    // private readonly defaultShowDM: boolean;
    // private readonly defaultUseSCAlternateUrl: boolean;
    // private readonly defaultShowDashMSG: Object;
    function LaunchDarklyService(sessionManager, storeService) {
        // Initializes default values for each LD flag.
        // Add more when more flags are added.
        // this.defaultShowADR = true;
        // this.defaultShowDM = true;
        // this.defaultUseSCAlternateUrl = false;
        // this.defaultShowDashMSG = {};
        var _this = this;
        this.sessionManager = sessionManager;
        this.storeService = storeService;
        this.flagChange = new Subject();
        /*
          Add newly created flags from DASH LD Project here to initialize them and set a default value
          { string: any } Must match LD flag key
        */
        this.flags = {
            "document-manager": false,
            "dash_app_adr": false,
            "dash_app_sc": false,
            "dash-message": {},
        };
        /*
          This data is consumed in > ally-nda-angular\src\app\services\app.list.service.ts
          All hide/show apps will be shown if Launch Darkly initialization fails.
          SmartCash will use normal URL initialization fails.
          Dash message will not be shown if initialization fails.
        */
        // this.defaultFlagData = {
        //   "document-manager": {
        //     current: this.defaultShowDM,
        //   },
        //   "dash_app_adr": {
        //     current: this.defaultShowADR,
        //   },
        //   "dash_app_sc": {
        //     current: this.defaultUseSCAlternateUrl,
        //   },
        //   "dash-message": {
        //     current: this.defaultShowDashMSG,
        //   },
        // };
        // this.storeService.write(SessionManagementAttrKey.defaultFlagData, this.defaultFlagData);
        // Sets the user key to whatever environment we are in
        this.userKey = "key-user-" + environment.env;
        //Initializes the context before the user has logged in
        this.preLoginContext = {
            key: this.userKey,
            kind: "user",
        };
        // Uses Environment Specific Client ID and user context to initialize connection to LaunchDarkly
        this.ldClient = initialize(environment.launchDarkly.clientSideId, this.preLoginContext);
        // If properly connected and initialized
        this.ldClient.on("initialized", function () {
            console.log("LaunchDarkly flags sucessfully initialized.");
        });
        // If failed to properly connect and initialize
        this.ldClient.on("failed", function () {
            console.log("LaunchDarkly flags failed to initialize.");
        });
        /*
          Will run to gather flag data regardless of success or failure
          Will loop through all flags that were set up above in this.flags
        */
        this.ldClient.on("ready", function (flags) {
            Object.entries(_this.flags).forEach(function (_a) {
                var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
                _this.ldClient.variation(key, value);
                _this.flags[key] = flags[key];
            });
        });
        // Tracks changes made to flags
        this.ldClient.on("change", function (flags) {
            Object.entries(_this.flags).forEach(function (_a) {
                var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
                if (flags[key] !== undefined) {
                    _this.flags[key] = flags[key];
                }
                _this.flagChange.next(_this.flags);
            });
            console.log("LaunchDarkly flags updated.");
        });
    }
    /*
      Resolves successfully any time after Launch Darkly has been successfully initialized.
      Used in Dealership Step 3 Component (registration) to make sure LD call completes before calling workflow.
      See https://jira.int.ally.com/browse/DRP-8447 for more information.
    */
    LaunchDarklyService.prototype.initializeLaunchDarklyForRegistration = function () {
        return this.ldClient.waitForInitialization();
    };
    // Fetches LD flags, keeps track of changes, and stores them in session service
    LaunchDarklyService.prototype.storeLDFlagsInSession = function () {
        var _this = this;
        this._subscription = this.flagChange.subscribe(function (flags) {
            _this.allLDFlags = flags;
            _this.showDM = flags["document-manager"].current;
            _this.showADR = flags["dash_app_adr"].current;
            _this.showDashMSG = flags["dash-message"].current;
            _this.useSCAlternateUrl = flags["dash_app_sc"].current;
            _this.writeFlagsToStorage();
        });
    };
    // Writes all Launch Darkly flags to storage.
    LaunchDarklyService.prototype.writeFlagsToStorage = function () {
        this.storeService.write("allLaunchDarklyFlags", this.allLDFlags);
        this.storeService.write("launchDarklyDMFlag", this.showDM);
        this.storeService.write("launchDarklyADRFlag", this.showADR);
        this.storeService.write("launchDarklyDashMSGFlag", this.showDashMSG);
        this.storeService.write("launchDarklySCFlag", this.useSCAlternateUrl);
        // this.storeService.write("defaultFlagData", this.defaultFlagData);
    };
    // Updates Context in LD to user context once the login has been successful
    LaunchDarklyService.prototype.updateContext = function () {
        //dealer user
        if (this.sessionManager.profile.userType === "D") {
            this.userContext = {
                kind: "user",
                key: this.sessionManager.profile.userId,
                firstName: this.sessionManager.profile.firstName,
                lastName: this.sessionManager.profile.lastName,
                email: this.sessionManager.profile.email,
                username: this.sessionManager.profile.userName,
                pdn: this.sessionManager.profile.dealership.pdn,
                environment: environment.env,
                userType: this.sessionManager.profile.userType
            };
        }
        //Ally user
        else {
            this.userContext = {
                kind: "user",
                key: this.sessionManager.profile.userId,
                firstName: this.sessionManager.profile.firstName,
                lastName: this.sessionManager.profile.lastName,
                email: this.sessionManager.profile.email,
                username: this.sessionManager.profile.userName,
                environment: environment.env,
                userType: this.sessionManager.profile.userType
            };
        }
        this.ldClient.identify(this.userContext);
    };
    return LaunchDarklyService;
}());
export { LaunchDarklyService };
