import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { initialize, LDClient, LDFlagSet } from "launchdarkly-js-client-sdk";
import { environment } from "../../../environments/environment";
import { SessionManagementService, SessionManagementAttrKey } from "../session-management.service";
import { StoreService } from "../store.service";

@Injectable()
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<Object> = new Subject<Object>();
  userContext: {};
  preLoginContext: {};
  userKey: string;

  _subscription: any;
  allLDFlags: Object;
  showDM: boolean;
  showADR: boolean;
  showDashMSG: any;
  useSCAlternateUrl: boolean;

  // Defaults, do not overwrite. Initialized in constructor ONLY.
  // private readonly defaultFlagData: Object;
  // private readonly defaultShowADR: boolean;
  // private readonly defaultShowDM: boolean;
  // private readonly defaultUseSCAlternateUrl: boolean;
  // private readonly defaultShowDashMSG: Object;

  constructor(
    private sessionManager: SessionManagementService,
    private storeService: StoreService
  ) {
    // Initializes default values for each LD flag.
    // Add more when more flags are added.
    // this.defaultShowADR = true;
    // this.defaultShowDM = true;
    // this.defaultUseSCAlternateUrl = false;
    // this.defaultShowDashMSG = {};

    /* 
      Add newly created flags from DASH LD Project here to initialize them and set a default value
      { string: any } Must match LD flag key
    */
    this.flags = {
      "document-manager": false, // Boolean
      "dash_app_adr": false, // Boolean
      "dash_app_sc": false, // Boolean
      "dash-message": {}, // JSON Object
    };

    /*
      This data is consumed in > ally-nda-angular\src\app\services\app.list.service.ts
      All hide/show apps will be shown if Launch Darkly initialization fails.
      SmartCash will use normal URL initialization fails.
      Dash message will not be shown if initialization fails.
    */
    // this.defaultFlagData = {
    //   "document-manager": {
    //     current: this.defaultShowDM,
    //   },
    //   "dash_app_adr": {
    //     current: this.defaultShowADR,
    //   },
    //   "dash_app_sc": {
    //     current: this.defaultUseSCAlternateUrl,
    //   },
    //   "dash-message": {
    //     current: this.defaultShowDashMSG,
    //   },
    // };

    // this.storeService.write(SessionManagementAttrKey.defaultFlagData, this.defaultFlagData);

    // Sets the user key to whatever environment we are in
    this.userKey = "key-user-" + environment.env;

    //Initializes the context before the user has logged in
    this.preLoginContext = {
      key: this.userKey,
      kind: "user",
    };

    // Uses Environment Specific Client ID and user context to initialize connection to LaunchDarkly
    this.ldClient = initialize(
      environment.launchDarkly.clientSideId,
      this.preLoginContext
    );

    // If properly connected and initialized
    this.ldClient.on("initialized", () => {
      console.log("LaunchDarkly flags sucessfully initialized.");
    });

    // If failed to properly connect and initialize
    this.ldClient.on("failed", () => {
      console.log("LaunchDarkly flags failed to initialize.");
    });

    /* 
      Will run to gather flag data regardless of success or failure
      Will loop through all flags that were set up above in this.flags
    */
    this.ldClient.on("ready", (flags) => {
      Object.entries(this.flags).forEach(([key, value]: [string, any]) => {
        this.ldClient.variation(key, value);
        this.flags[key] = flags[key];
      });
    });

    // Tracks changes made to flags
    this.ldClient.on("change", (flags) => {
      Object.entries(this.flags).forEach(([key, value]: [string, any]) => {
        if (flags[key] !== undefined) {
          this.flags[key] = flags[key];
        }
        this.flagChange.next(this.flags);
      });
      console.log("LaunchDarkly flags updated.");
    });
  }

  /*
    Resolves successfully any time after Launch Darkly has been successfully initialized.
    Used in Dealership Step 3 Component (registration) to make sure LD call completes before calling workflow.
    See https://jira.int.ally.com/browse/DRP-8447 for more information.
  */
  initializeLaunchDarklyForRegistration(): Promise<void> {
    return this.ldClient.waitForInitialization();
  }

  // Fetches LD flags, keeps track of changes, and stores them in session service
  storeLDFlagsInSession(): void {
    this._subscription = this.flagChange.subscribe((flags) => {
      this.allLDFlags = flags;
      this.showDM = flags["document-manager"].current;
      this.showADR = flags["dash_app_adr"].current;
      this.showDashMSG = flags["dash-message"].current;
      this.useSCAlternateUrl = flags["dash_app_sc"].current;
      this.writeFlagsToStorage();
    });
  }

  // Writes all Launch Darkly flags to storage.
  writeFlagsToStorage(): void {
    this.storeService.write("allLaunchDarklyFlags", this.allLDFlags);
    this.storeService.write("launchDarklyDMFlag", this.showDM);
    this.storeService.write("launchDarklyADRFlag", this.showADR);
    this.storeService.write("launchDarklyDashMSGFlag", this.showDashMSG);
    this.storeService.write("launchDarklySCFlag", this.useSCAlternateUrl);
    // this.storeService.write("defaultFlagData", this.defaultFlagData);
  }

  // Updates Context in LD to user context once the login has been successful
  updateContext(): void {
    //dealer user
    if (this.sessionManager.profile.userType === "D") {
      this.userContext = {
        kind: "user",
        key: this.sessionManager.profile.userId,
        firstName: this.sessionManager.profile.firstName,
        lastName: this.sessionManager.profile.lastName,
        email: this.sessionManager.profile.email,
        username: this.sessionManager.profile.userName,
        pdn: this.sessionManager.profile.dealership.pdn,
        environment: environment.env,
        userType: this.sessionManager.profile.userType
      };
    }
    //Ally user
    else {
      this.userContext = {
        kind: "user",
        key: this.sessionManager.profile.userId,
        firstName: this.sessionManager.profile.firstName,
        lastName: this.sessionManager.profile.lastName,
        email: this.sessionManager.profile.email,
        username: this.sessionManager.profile.userName,
        environment: environment.env,
        userType: this.sessionManager.profile.userType
      };
    }
    this.ldClient.identify(this.userContext);
  }
}
